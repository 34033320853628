const settings = {
  BASE_WS_URL: import.meta.env.VITE_WS_URL,
  baseApiUrl: import.meta.env.VITE_API_URL,
  establishment_id: import.meta.env.VITE_ESTABLISHMENT_ID || null,
  firebaseConfig: {
    apiKey: "AIzaSyDuHa074uVeMwFoKqYwJfihHmo1FMvUTpo",
    authDomain: "healthtracker-ed41d.firebaseapp.com",
    projectId: "healthtracker-ed41d",
    storageBucket: "healthtracker-ed41d.appspot.com",
    messagingSenderId: "889472551294",
    appId: "1:889472551294:web:6f202d2951738075638b2f"
  },
  firebasePublicVapidKey: 'BL__9l6H4VGIsSEgjWZSLvfelsVG_VgVTO6nBUL6SAN4raXdcEXQTigST9PD4yObEViOID8fvqxKOnf-Mk8wr5M',
  defaultEstablishment: import.meta.env.VITE_ESTABLISHMENT_ID || null,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'development',
  enableSentry: import.meta.env.VITE_SENTRY_ENABLED || false
}

export default settings