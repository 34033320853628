import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import settings from '../../settings'

export const initializeFirebase = async () => {
  try {
    const app = initializeApp(settings.firebaseConfig)

    const messaging = getMessaging(app)

    const token = await getToken(messaging, { vapidKey: settings.firebasePublicVapidKey })

    onMessage(messaging, (payload) => {
      const notificationOptions = {
        body: payload.data.body
      }
      console.log(payload)
      new Notification(payload.data.title, notificationOptions);
    })

    return token
  } catch (err) {
    console.log('error initializing firebase', err)
  }
}