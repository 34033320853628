import { acceptHMRUpdate, defineStore } from 'pinia'
import axios from '@/plugins/axios'
import { useToast } from 'vue-toastification'

const toast = useToast()

const getCurrentProfile = () => {
  const profile = localStorage.getItem('currentProfile')
  if (profile) {
    return JSON.parse(profile)
  }
  return null
}

const getUser = () => {
  const user = localStorage.getItem('user')
  if (user) {
    return JSON.parse(user).user
  }
  return null
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: getUser(),
    newUser: {
      first_name: '',
      last_name: '',
      rut: '',
      address: '',
      commune: '',
      birth_date: null,
      sex: '',
      nationality: '',
      phone: '',
      email: '',
      has_additional_insurance: false,
      additional_insurance_name: '',
      prevision: '',
      health_plan: '',
      serial_number: '',
      code: '',
      password1: '',
      password2: '',
      account_type: '',
    },
    authForm: false,
    currentProfile: getCurrentProfile(),
    step: 1
  }),

  getters: {
    getAuthForm: (state) => state.authForm,
  },

  actions: {

    setCurrentProfile(profile) {
      this.currentProfile = profile
      if (!profile) {
        localStorage.removeItem('currentProfile')
        return
      }
      localStorage.setItem('currentProfile', JSON.stringify(profile))
    },
    setUser(user) {
      this.user = user
      if (!user) {
        localStorage.removeItem('user')
        return
      }
      localStorage.setItem('user', JSON.stringify(user))
    },

    async login(data, params) {
      return axios
        .post('/patient_app_api/auth/login/', data, { params })
        .then((res) => {
          this.setUser(res.data)
          return res
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.setUser({
              rut: data.rut,
              password: data.password,
              email: err.response.data.email
            })
          }
          return err.response
        })
    },

    async logout() {
      this.setUser(null)
      this.setCurrentProfile(null)
      this.setAuthForm()
      localStorage.clear()
    },

    setAuthForm() {
      this.authForm = !this.authForm
    },

    async addNotificationToken(data) {
      return axios
        .post('/patient_app_api/notification/push-notification/register-device/', data)
        .then((res) => res)
        .catch((err) => err.response)
    },

    async notificationSettings(data) {
      return axios
        .put('/patient_app_api/auth/user/settings/', data)
        .then((res) => res)
        .catch((err) => {
          console.log(err)
          toast.error('Error al modificar las Notificaciones')
        })

    },

    async getNotificationSettings() {
      return axios
        .get('/patient_app_api/auth/user/settings/')
        .then((res) => res)
        .catch((err) => err.response)
    },

    nextStep() {
      this.step++
    },

    previousStep() {
      this.step--
    },

    async checkRegistration(data) {
      return axios
        .post('/patient_app_api/auth/check-registration/', data)
        .then((res) => res)
        .catch((err) => {
          console.log(err.response)
          toast.error('Error: El número de serie no es válido')
          return err.response
        })
    },

    async checkVerificationCode(data) {
      return axios
        .post('/patient_app_api/auth/check-email-verification-code/', data)
        .then((res) => res)
        .catch((err) => {
          console.log(err.response)
          toast.error('Error en el Código')
          return err.response
        })
    },

    async createNewUser() {
      const data = {
        ...this.newUser
      }
      return axios
        .post('/patient_app_api/auth/registration/', data)
        .then((res) => {
          this.setUser(res.data)
          return res
        })
        .catch((err) => err.response)
    },

    async getPrevision() {
      return axios
        .get('/api/operation/prevision/')
        .then((res) => res)
        .catch((err) => err.response)
    },

    async getCommune() {
      return axios
        .get('/api/establishment/commune/')
        .then((res) => res)
        .catch((err) => err.response)
    },
  }
})
