import axios from 'axios'
import settings from '../../settings'
import getDeviceCode from '@/utils/autorizeDevice.js'
const instance = settings.baseApiUrl ? axios.create({
  baseURL: `${settings.baseApiUrl}`
}) : axios.create()


instance.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem('user'))
  if (user && Object.hasOwn(user, 'key')) {
    config.headers.Authorization = `Token ${user.key}`
  }
  config.headers = {
    ...config.headers,
    'X-User-Device-Id': getDeviceCode()
  }
  if (settings.defaultEstablishment) {
    config.params = {
      ...config.params,
      patient_app_establishment_id: settings.defaultEstablishment
    }
  }
  return config
}, (error) => { Promise.reject(error) })

export default instance
