<template lang="pug">
v-app
  v-main(class="pt-2 pt-sm-2 pt-xs-2 pt-md-0")
    router-view
</template>

<script setup>
import { ref } from 'vue'
import { useAuthStore } from '@/store/useAuthStore'
import { useSiteStore } from '@/store/useSiteStore'
import { nextTick } from 'vue';
import settings from '../settings'
import { initializeFirebase } from './plugins/firebase'
import { useFavicon } from '@vueuse/core'

const authStore = useAuthStore()
const siteStore = useSiteStore()
const primary_color = ref('')
const secondary_color = ref('')
const icon = useFavicon()

const getSiteConfig = async() => {
  // recupero hostname y extraigo subdominio
  const host = window.location.hostname
  const subdomain = host.split('.')[0]
  // almaceno subdominio en localstorage
  localStorage.setItem('subdomain', subdomain)
  const params = settings.establishment_id ? {
    patient_app_establishment_id: settings.establishment_id,
  } : {}
  // recupero configuracion del sitio
  const site = await siteStore.getSiteConfig(params)
  // cargo colores convertidos a rgb
  primary_color.value = toRGB(site.primary_color)
  secondary_color.value = toRGB(site.secondary_color)
  icon.value = site.favicon_32
  // almaceno configuracion del sitio en localstorage
  localStorage.setItem('siteConfig', JSON.stringify(site))
  nextTick(() => {
    // cambio el titulo de la pagina
    document.title = site.name
  })
}

const initFirebase = async () => {
  const token = await initializeFirebase()
  if (token) {
    authStore.addNotificationToken({ device_token: token })
  }
}
initFirebase()
const toRGB = (color) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ].join(',') : null;
}
getSiteConfig()
</script>
<style lang="scss">
@import '@/styles/global.scss';
</style>
<style>
.v-theme--light {
  --v-theme-primary: v-bind('primary_color') !important;
  --v-theme-secondary: v-bind('secondary_color') !important;
}
</style>
