const generateDeviceCode = () => {
  return crypto.randomUUID()
}

/**
 * Recupera el código del dispositivo desde el local Storage
 */
const getDeviceCode = () => {
  let deviceCode = localStorage.getItem('deviceCode')
  if (!deviceCode) {
    deviceCode = generateDeviceCode()
    localStorage.setItem('deviceCode', deviceCode)
  }
  return deviceCode
}

export default getDeviceCode
