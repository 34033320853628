// Composables
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      isPublic: true
    }
  },
  {
    path: '/registro',
    name: 'registro',
    component: () => import('@/views/RegisterView.vue'),
    meta: {
      isPublic: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      isPublic: true
    }
  },
  {
    path: '/',
    component: () => import('@/layouts/default.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/HomeView.vue'),
        meta: {
          isPublic: false
        }
      },
      {
        path: 'contenido-educativo',
        name: 'contenido-educativo',
        redirect: { name: 'contenido-educativo-list' },
        meta: {
          isPublic: false
        },
        children: [
          {
            path: '',
            name: 'contenido-educativo-list',
            component: () => import('@/views/EducativeContentView.vue'),
            meta: {
              isPublic: false
            }
          },
          {
            path: ':slug',
            name: 'contenido-educativo-detail',
            component: () => import('@/views/EducativeContentDetailView.vue'),
            meta: {
              isPublic: false
            }
          },
        ]
      },
      {
        path: 'contenido-educativo/:slug',
        name: 'contenido-educativo-detail',
        redirect: { name: 'contenido-educativo-detalle' },
        meta: {
          isPublic: false
        },
        children: [
          {
            path: '',
            name: 'contenido-educativo-detalle',
            component: () => import('@/views/EducativeContentDetailView.vue'),
            meta: {
              isPublic: false
            }
          },
        ]
      },
      {
        path: 'encuestas',
        name: 'encuestas',
        redirect: { name: 'listaEncuestas' },
        meta: {
          isPublic: false,
        },
        children: [
          {
            path: '',
            name: 'listaEncuestas',
            component: () => import('@/views/SurveyView.vue'),
            meta: {
              isPublic: false,
            },
          },
          {
            path: 'ver/:id',
            name: 'verEncuesta',
            component: () => import('@/views/SurveyFormAnswersView.vue'),
            meta: {
              isPublic: false
            }
          },
          {
            path: ':id',
            name: 'contestarEncuesta',
            component: () => import('@/views/SurveyFormView.vue'),
            meta: {
              isPublic: false
            }
          },
        ]
      },
      {
        path: '/configuracion',
        name: 'configuracion',
        component: () => import('@/views/ConfigurationView.vue'),
        meta: {
          isPublic: false
        }
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user'))

  if (to.name === 'login' && user && Object.hasOwn(user, 'key')) {
    return next({ name: 'home' })
  }
  if (to.name === 'login' || to.name === 'registro') {
    return next()
  }
  if (!user && !to.meta.public) {
    return next({ name: 'login' })
  }
  if (!to.meta.allowedGroups) {
    return next()
  }
  next()
})

export default router
