import { defineStore } from 'pinia'
import axios from '@/plugins/axios'

export const useSiteStore = defineStore('siteStore', {
  state: () => ({
    siteConfig: {
      primary_color: '',
      secondary_color: '',
      logo: '',
      name: '',
      favicon_32: ''
    },
    subdomain: ''
  }),
  getters: {
    getSubdomain() {
      const subdomain = localStorage.getItem('subdomain')
      return subdomain.charAt(0).toUpperCase() + subdomain.slice(1)
    }
  },
  actions: {
    setSiteConfig(config, subdomain) {
      this.siteConfig = config
      this.subdomain = subdomain
      localStorage.setItem('siteConfig', JSON.stringify(config))
      localStorage.setItem('subdomain', subdomain)
    },
    async getSiteConfig(params) {
      return axios
        .get('/patient_app_api/site_settings/site_settings/theme/', { params })
        .then((res) => {
          this.setSiteConfig(res.data)
          return res.data
        })
        .catch((err) => err.response)
    },
    setSiteConfigFromLocalStorage() {
      const siteConfig = localStorage.getItem('siteConfig')
      const subdomain = localStorage.getItem('subdomain')
      if (siteConfig && subdomain) {
        this.siteConfig = JSON.parse(siteConfig)
        this.subdomain = subdomain
      }
    }
  }
})

