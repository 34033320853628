/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify'
import pinia from '../store'
import router from '../router'
import Toast from 'vue-toastification'
import sentryPlugin from './sentry'

import 'vue-toastification/dist/index.css'

export function registerPlugins(app) {
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(Toast,
      {
        transition: 'Vue-Toastification__bounce',
        position: 'bottom-right',
        maxToasts: 20,
        newestOnTop: true
      })
  sentryPlugin.registerSentry(app)
}
